import React, { useCallback, useRef, useState } from 'react';
import { useReactTable, getCoreRowModel, flexRender, getPaginationRowModel, getSortedRowModel } from '@tanstack/react-table';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';

import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  LinearProgress,
} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import { Skeleton } from '@mui/material';
import TablePaginationActions from './TablePaginationActions';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { map, size } from 'lodash';
import { getPageCount } from './utils';
import { DEFAULT_PAGINATION, DEFAULT_SORTING } from './constants';
import { useGridFlexLayout } from './use-grid-flex-layout';

export const IconWrapper = styled('div')({
  width: 24,
  height: 24,
  flexShrink: 0,
  '& > svg': {
    width: '100%',
    height: 'auto',
  },
});

const useStyles = makeStyles(theme => ({
  loadingCell: {
    // meh
    padding: '0px !important',
    height: 5,
  },
  table: {
    backgroundColor: '#fff',
    '& a': {
      color: '#000',
      textDecoration: 'underline',
      transition: theme.transitions.create(['color']),
      '&:hover': {
        color: `${theme.palette.secondary.main}`,
      },
    },
  },
  odd: {
    backgroundColor: props => props.oddColor ?? '#ffffff',
  },
  even: {
    backgroundColor: props => props.evenColor ?? '#F7F4F0',
  },
  noDataCell: {
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '10px',
    },
  },
  cell: {
    maxWidth: 'none',
    display: 'flex',
    alignItems: 'center',
  },
  footer: {
    backgroundColor: '#fff',
  },
  row: {
    '&:hover': {
      filter: 'brightness(0.95)',
    },
  },
  rowClickable: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

function randomIntFromInterval(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

/**
 * AsyncTable renders a data table with support for asynchronous data loading by default (async=true). Synchronous loading is supported
 * by adding the async=false prop.
 *
 * It leverages @tanstack/react-table for advanced table functionalities and Material-UI components for styling and layout.
 * The component is designed to be flexible, allowing developers to control pagination and sorting either internally or externally.
 *
 * **Features:**
 * - **Asynchronous Data Loading**: Supports server-side data fetching when the `async` prop is true.
 * - **Pagination**: Handles pagination with support for both controlled (external) and uncontrolled (internal) state.
 * - **Sorting**: Allows column sorting with controlled and uncontrolled state management.
 * - **Loading States**: Displays loading indicators and skeletons while data is being fetched.
 * - **Error Handling**: Shows an error message when data fetching fails.
 * - **Row Click Handling**: Provides a callback for row click events.
 * - **Customizable Rows and Cells**: Accepts functions to customize row properties and cell rendering.
 *
 * @param {Object} props - The properties for configuring the AsyncTable.
 * @param {boolean} [props.async=true] - Determines if the table uses server-side data fetching for pagination and sorting.
 * @param {Array<Object>} [props.columns=[]] - An array of column definitions conforming to @tanstack/react-table specifications.
 * @param {Object} [props.pagination] - The pagination state, containing `pageIndex` and `pageSize`.
 * @param {Array<Object>} [props.sorting] - The sorting state, an array of sorting objects with `id` and `desc` properties.
 * @param {number} [props.count=-1] - The total number of data entries, used for calculating the total number of pages.
 * @param {boolean} [props.isLoading=false] - Indicates if the initial data is still loading.
 * @param {boolean} [props.isFetching=false] - Indicates if data is being fetched due to pagination or sorting changes.
 * @param {boolean} [props.isError=false] - Indicates if there was an error loading the data.
 * @param {Array<Object>} [props.data=[]] - The data array to display in the table.
 * @param {string} [props.noResultsText='No data found'] - The message displayed when there is no data.
 * @param {string} [props.errorText='An error occurred'] - The message displayed when an error occurs during data fetching.
 * @param {Function} [props.setPagination] - Function to update the pagination state; if omitted, internal state is used.
 * @param {Function} [props.setSorting] - Function to update the sorting state; if omitted, internal state is used.
 * @param {Function} [props.onRowClick] - Callback function invoked when a table row is clicked, receives `(event, row)` as arguments.
 * @param {Function} [props.rowProps] - Function to provide additional props to each row, receives `(row, index)` and returns props.
 * @param {Object} [rest] - Additional props to be passed to the root div element.
 *
 * @returns {JSX.Element} The rendered AsyncTable component.
 */
export default function AsyncTable(props) {
  const {
    async = true,
    columns = [],
    pagination,
    sorting,
    count = -1,
    isLoading = false,
    isFetching = false,
    isError = false,
    data = [],
    noResultsText = 'No data found',
    errorText = 'An error occurred',
    setPagination,
    setSorting,
    onRowClick,
    rowProps,
    ...rest
  } = props;

  const classes = useStyles(props);

  // it is assumed that if you do not pass setPagination, setSorting you want to use internal
  // pagination and sorting
  const [internalPagination, setInternalPagination] = useState(DEFAULT_PAGINATION);
  const [internalSorting, setInternalSorting] = useState(DEFAULT_SORTING);

  const page = pagination ?? internalPagination;

  const pageCount = getPageCount(count, page?.pageSize);

  const onSortingChange = useCallback(
    updater => {
      let newSortingValue = updater instanceof Function ? updater(sorting) : updater;
      // by default if all you use is an accessorKey: some.deep.value, tanstack table will
      // automatically convert this to a snake case'd version: some_deep_value. this value is used
      // internally to track which columns have been sorted.
      //
      // this bit of code coerces this back and sets it as 'key', so it's available to the sorting function
      // for easier API querying. Alternatively, you could pass this into `id` instead of `accessorKey`,
      // but then you have to manually handle your getter with a custom accessorFn
      newSortingValue = map(newSortingValue, o => ({
        ...o,
        key: o.id.replace('_', '.'),
      }));
      setSorting(newSortingValue);
    },
    [sorting, setSorting],
  );

  const table = useReactTable({
    data,
    columns,
    pageCount,
    state: {
      pagination: pagination ?? internalPagination,
      sorting: sorting ?? internalSorting,
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination ?? setInternalPagination,
    // server-side pagination support
    manualPagination: async,
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting ? onSortingChange : setInternalSorting,
    // server-side sorting support
    manualSorting: async,
  });

  const headerRef = useRef();
  const grid = useGridFlexLayout({ table, headerRef });
  const rowStyles = {
    display: 'grid',
    gridTemplateColumns: grid.gridTemplateString,
  };

  const colCount = table?.getAllColumns()?.length;

  return (
    <div {...rest}>
      <TableContainer>
        <MuiTable className={classes.table}>
          <TableHead ref={headerRef}>
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id} style={rowStyles}>
                {headerGroup.headers.map(header => {
                  return (
                    <TableCell key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <div
                          className={header.column.getCanSort() ? 'cursor-pointer select-none' : ''}
                          style={{
                            display: 'flex',
                            gap: 20,
                          }}
                          onClick={header.column.getToggleSortingHandler()}
                          title={
                            header.column.getCanSort()
                              ? header.column.getNextSortingOrder() === 'asc'
                                ? 'Sort ascending'
                                : header.column.getNextSortingOrder() === 'desc'
                                ? 'Sort descending'
                                : 'Clear sort'
                              : undefined
                          }
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          <IconWrapper>
                            {{
                              asc: <ArrowUpwardIcon />,
                              desc: <ArrowDownwardIcon />,
                            }[header.column.getIsSorted()] ?? null}
                          </IconWrapper>
                        </div>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell className={classes.loadingCell} colSpan={colCount}>
                {isFetching && <LinearProgress color="secondary" />}
              </TableCell>
            </TableRow>
            {!isLoading && isError && (
              <TableRow>
                <TableCell className={classes.noDataCell} colSpan={colCount}>
                  <div>
                    <ErrorOutlineIcon color="secondary" />
                    {errorText}
                  </div>
                </TableCell>
              </TableRow>
            )}
            {!isLoading && !isError && size(data) === 0 && (
              <TableRow>
                <TableCell className={classes.noDataCell} colSpan={colCount}>
                  <div>
                    <ErrorOutlineIcon color="secondary" />
                    {noResultsText}
                  </div>
                </TableCell>
              </TableRow>
            )}
            {isLoading
              ? Array.from({ length: 5 }, (_o, i) => i).map(i => (
                  <TableRow key={i} className={i % 2 ? classes.even : classes.odd} style={rowStyles}>
                    {table.getAllColumns().map(cell => {
                      return (
                        <TableCell key={cell.id}>
                          <Skeleton
                            height={10}
                            width={`${randomIntFromInterval(20, 80)}%`}
                            variant="rectangular"
                            component="div"
                            role="status"
                            aria-label="content loading"
                          />
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))
              : table.getRowModel().rows.map((row, i) => {
                  return (
                    <TableRow
                      key={row.id}
                      className={clsx(classes.row, i % 2 ? classes.even : classes.odd, onRowClick && classes.rowClickable)}
                      onClick={e => onRowClick?.(e, row)}
                      {...(rowProps ? rowProps(row, i) : {})}
                      style={rowStyles}
                      data-test-id={(() => {
                        const id = row._id || row.original?.id || row.original?.supplierCode || `row-${i}`;
                        return id ? `row-${id}` : undefined;
                      })()}
                    >
                      {row.getVisibleCells().map(cell => {
                        return (
                          <TableCell className={classes.cell} key={cell.id}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
          </TableBody>
        </MuiTable>
      </TableContainer>

      {/* give pagination its own table so it doesn't scroll off in mobile responsive */}
      <MuiTable className={classes.footer}>
        <TableBody>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50]}
              colSpan={colCount || 0}
              count={count || 0}
              rowsPerPage={page?.pageSize || DEFAULT_PAGINATION.pageSize}
              page={page.pageIndex || DEFAULT_PAGINATION.pageIndex}
              slotProps={{
                select: {
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                },
              }}
              onPageChange={(_, page) => {
                table.setPageIndex(page);
              }}
              onRowsPerPageChange={e => {
                table.setPageSize(Number(e.target.value));
              }}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableBody>
      </MuiTable>
    </div>
  );
}
