import { useQuery } from '@tanstack/react-query';
import { useApi } from 'common/hooks/api';

export const useClientsByAgentQuery = ({ options, queryOptions }) => {
  const Api = useApi();

  return useQuery({
    queryKey: ['clients', options],
    queryFn: async () => {
      const response = await Api.getClientsByAgent(options);
      return response.data;
    },
    ...queryOptions,
    cacheTime: 0,
  });
};
