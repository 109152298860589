import { useQuery } from '@tanstack/react-query';
import { useApi } from 'common/hooks/api';

// Keys
export const agencyAgentsListKey = agencyId => ['agency', agencyId, 'agents'];

export const useGetAgencyAgentsListQuery = agencyId => {
  const Api = useApi();

  return useQuery({
    queryKey: agencyAgentsListKey(agencyId),
    queryFn: async () => {
      const response = await Api.getAgencyAgents(agencyId);
      const { data } = response;

      return data;
    },
    cacheTime: 0,
  });
};

export const useGetAgencyDetailsQuery = agencyId => {
  const Api = useApi();

  return useQuery({
    queryKey: ['agency', agencyId, 'details'],
    queryFn: async () => {
      const response = await Api.getAgency(agencyId);
      const { data } = response;
      return data;
    },
    cacheTime: 0,
  });
};
