import logger from 'itrvl-logger';
import { useCreateAgentMutation } from '../mutations/agent';

const log = logger(__filename);

const cleanUserData = data => ({
  ...data,
  email: data.email.trim(),
  firstName: data.firstName.trim(),
  lastName: data.lastName.trim(),
});

export const useCreateAgent = (agencyId, onNewAgent) => {
  const createAgentMutation = useCreateAgentMutation();

  const submitNewAgent = async (data, modal, setError) => {
    const sanitizedData = cleanUserData(data);
    const fullName = `${sanitizedData.firstName} ${sanitizedData.lastName}`;
    try {
      const response = await createAgentMutation.mutateAsync({
        agencyId,
        ...sanitizedData,
        fullName,
      });
      onNewAgent(response);
      modal.hide();
    } catch (err) {
      if (err.response?.status === 422) {
        const errorMessage = err.response.data.error.details.messages.email[0];
        setError('email', {
          type: 'manual',
          message: errorMessage,
        });
      } else {
        log.error('An error occurred:', err);
      }
    }
  };

  return { submitNewAgent, isLoading: createAgentMutation.isLoading };
};
