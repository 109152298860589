import React, { useState } from 'react';
import { Autocomplete, TextField, Box } from '@mui/material';
import { useDebounce } from 'react-use';
import { useRegionsSearchPagedQuery } from 'queries/regions';
import { styled } from '@mui/system';
import ListboxComponent from '../v2/FormElements/common/AutocompleteListbox';

const ITEMS_PER_PAGE = 50;
const DEBOUNCE_DELAY = 300;

export default function RegionAutocomplete({ value, onChange, selectWholeCountry = false, TextFieldProps, ...rest }) {
  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = useState(false);
  const listboxRef = React.useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  useDebounce(
    () => {
      setSearchTerm(inputValue);
    },
    DEBOUNCE_DELAY,
    [inputValue, setSearchTerm],
  );

  const { data, fetchNextPage, hasNextPage, isLoading } = useRegionsSearchPagedQuery({
    term: searchTerm,
    enabled: open,
    pageSize: ITEMS_PER_PAGE,
  });

  const options = React.useMemo(() => {
    if (!data) return [];
    return data.pages.flatMap(page => page.data);
  }, [data]);

  const onGroupHeader = group => {
    //TODO - load from API, not guaranteed to have all regions
    const data = options.filter(option => option.country?.name === group);
    if (value) {
      data.concat(value);
    }
    onChange(undefined, data);
  };

  return (
    <Autocomplete
      value={value}
      onChange={onChange}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      options={options}
      loading={isLoading}
      inputValue={inputValue}
      // this disables "search as you type", needed or you get conflicting UI between
      // internal search and the delay to server roundtrip
      filterOptions={x => x}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      isOptionEqualToValue={(option, value) => {
        return option?.regionCode === value?.regionCode;
      }}
      getOptionKey={option => option?.regionCode}
      getOptionLabel={option => option?.name ?? ''}
      groupBy={option => option?.country?.name}
      renderInput={params => (
        <TextField
          variant="standard"
          InputLabelProps={{ shrink: false }}
          {...params}
          placeholder="Select a region"
          inputProps={{
            ...params.inputProps, // merge in the default input props
            'aria-label': 'select a region',
          }}
          {...TextFieldProps}
        />
      )}
      renderOption={(props, option) => {
        // spreading a "key" shows an error
        const { key, ...rest } = props;
        return (
          <Box component="li" key={key} {...rest}>
            {option.name} ({option.regionCode})
          </Box>
        );
      }}
      renderGroup={
        !selectWholeCountry
          ? undefined
          : params => {
              return (
                <li key={params.key}>
                  <GroupHeader onClick={() => onGroupHeader(params.group)}>{params.group}</GroupHeader>
                  <GroupItems>{params.children}</GroupItems>
                </li>
              );
            }
      }
      ListboxComponent={ListboxComponent}
      ListboxProps={{
        ref: listboxRef,
        id: 'region-list',
        hasNextPage,
        fetchNextPage,
        isLoading,
      }}
      {...rest}
    />
  );
}

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  // top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  cursor: 'pointer',
}));

const GroupItems = styled('ul')({
  padding: 0,
});
