import { Dinero } from 'itrvl-pricing';
import logger from 'itrvl-logger';
const { getCosting } = require('./availability');
const { find, get, set, cloneDeep, reduce, map, forEach, uniq } = require('lodash');
const moment = require('moment');
const log = logger(__filename);
log.trace(__filename);

const ALL_SYSTEM_TYPES = ['Single', 'Staff', 'Twin', '_Double', 'Family'];

const getCurrencySymbol = currency =>
  Dinero({ amount: 0, currency })
    .toFormat()
    .replace(/[\d,.]/g, '')
    .trim();

// Wilderness has a strange idea of true and false:
// true: '-1', '1', 'true' and who knows what else...
// false: '', '0', 0, '-', 'false'
const isWildernessTruthy = value => !['', '-', '0', 0, 'false'].includes(value);

const formatApproximateValue = dinero => {
  const symbol = getCurrencySymbol(dinero.getCurrency());
  const num = dinero.toUnit();
  if (num >= 1000000000) {
    return symbol + (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'b';
  }
  if (num >= 1000000) {
    return symbol + (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'm';
  }
  if (num >= 100000) {
    return symbol + (num / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
  }
  return symbol + num.toFixed(0);
};

const optionKeysForSupplierCode = (supplierCode, roomTypesConfig) => {
  let roomTypes = get(roomTypesConfig, supplierCode);
  let optionKeys = reduce(
    ['Twin', 'Family', '_Double'],
    (acc, systemType) => {
      let optionKeysForSystemType = map(get(roomTypes, `${systemType}.options`), option => {
        return `${option.optionKey}-${option.roomTypeId}`;
      });
      acc = acc.concat(uniq(optionKeysForSystemType));
      return acc;
    },
    [],
  );
  return optionKeys;
};

const rateForRoomWithRule = (room, rule) => {
  const costing = getCosting([room]);

  const rate = {
    amount: 0,
    currency: 'USD',
    name: 'Unknown',
    detail: 'No pricing rule found',
    ruleFound: false,
    isClosed: false,
    invalidPax: false,
  };

  if (!rule) {
    return rate;
  }
  if (rule.prov === 'C') {
    rate.isClosed = true;
    return rate;
  }

  const roomPax = room.adults + get(room, 'children', []).length;
  if (room.roomType === 'fr' && get(rule, 'guestNormal') && roomPax > rule.guestNormal) {
    rate.invalidPax = true;
    return rate;
  }
  // GH #2609
  if (get(rule, 'normalAdults') && room.adults > Number(rule.normalAdults)) {
    rate.invalidPax = true;
    return rate;
  }
  let derivedRate;
  let detail;
  if (rule.perPerson === true) {
    derivedRate = costing.single * rule.singleRate + (costing.share * rule.twinRate) / 2 + costing.children * rule.childRate;
    detail = `Pricing per person: ${costing.single} single * ${rule.singleRate}, ${costing.share} share * ${rule.twinRate} / 2, ${costing.children} children * ${rule.childRate} === ${derivedRate}`;
  } else {
    derivedRate = Math.ceil(costing.pax / rule.maxUnits) * rule.groupRate;
    detail = `Pricing per room: ${costing.pax} pax / ${rule.maxUnits} max pax per unit * ${rule.groupRate} === ${derivedRate}`;
  }
  return { amount: derivedRate, currency: rule.currency, name: rule.room, detail, ruleFound: true, isClosed: false };
};

const calculateRateForRooms = (supplierCode, rates, rooms, _roomTypes) => {
  // @TODO: special case for wilderness properties that may have twin/double/family capable rooms + suites (JAO001)
  //    const isWilderness = get(lodgeList, `${supplierCode}.availabilityInterface`) === 'Wish' ? true : false;

  // console.log(`calculateRateForRooms: ${supplierCode}, rates: `,rates,', rooms, ',rooms,', _roomTypes: ',_roomTypes)
  const roomTypes = cloneDeep(get(_roomTypes, supplierCode));
  //    if (isWilderness) {
  if (!get(roomTypes, '_Double.default.optionKey')) {
    set(roomTypes, '_Double.default', get(roomTypes, 'Twin.default'));
    set(roomTypes, '_Double.options', get(roomTypes, 'Twin.options'));
  }
  //    }

  let rateForAllRooms = reduce(
    rooms,
    (acc, room) => {
      let systemTypeForRoom = 'Twin';
      switch (room.roomType) {
        case 'dr':
          // Doubles are okay with twins
          systemTypeForRoom = '_Double';
          break;
        case 'sr':
          systemTypeForRoom = '_Double';
          break;
        case 'tr':
          // Twins are not okay with doubles
          systemTypeForRoom = 'Twin';
          break;
        case 'fr':
          systemTypeForRoom = 'Family';
          break;
        default:
          systemTypeForRoom = 'Twin';
          break;
      }

      let optionKeyForRoom = get(roomTypes, `${systemTypeForRoom}.default.optionKey`);
      let roomTypeIdForRoom = get(roomTypes, `${systemTypeForRoom}.default.roomTypeId`);
      systemTypeForRoom = get(roomTypes, `${systemTypeForRoom}.default.systemType`, systemTypeForRoom);

      if (room.roomTypeId && room.optionKey) {
        let selectedRoomTypeId = get(room.roomTypeId, supplierCode);
        let selectedOptionKey = get(room.optionKey, supplierCode);
        let roomOptions = get(roomTypes, `Any.options`);
        let roomOption = find(roomOptions, { roomTypeId: String(selectedRoomTypeId), optionKey: String(selectedOptionKey) });

        if (roomOption) {
          optionKeyForRoom = roomOption.optionKey;
          roomTypeIdForRoom = roomOption.roomTypeId;
          systemTypeForRoom = roomOption.systemType;
        }
      }
      // console.log(`optionKeyForRoom: ${optionKeyForRoom}, roomTypeIdForRoom: ${roomTypeIdForRoom}`);
      if (!optionKeyForRoom) {
        acc.roomTypeNotPresent = true;
      }
      if (!optionKeyForRoom || acc.configNotAvailable === true) {
        acc.configNotAvailable = true;
        acc.ruleFound = false;
        return acc;
      }
      let provForRoom = get(
        find(get(roomTypes, `Any.options`, []), { roomTypeId: roomTypeIdForRoom, optionKey: optionKeyForRoom }),
        'prov',
      );
      let lastRateDateForRoom = get(
        find(get(roomTypes, `Any.options`, []), { roomTypeId: roomTypeIdForRoom, optionKey: optionKeyForRoom }),
        'lastRateDate',
      );

      acc.lastRateDate = lastRateDateForRoom;
      acc.lastRateProv = provForRoom;

      let rateForRoom = rateForRoomWithRule(room, find(rates, { optionKey: optionKeyForRoom, roomTypeId: roomTypeIdForRoom }));
      if (rateForRoom.isClosed) {
        acc.configNotAvailable = true;
        acc.isClosed = true;
        return acc;
      }
      if (rateForRoom.invalidPax) {
        acc.invalidPax = true;
        acc.configNotAvailable = true;
        return acc;
      }
      if (rateForRoom.ruleFound === false) {
        acc.ruleFound = false;
        if (acc.lastRateProv === 'T') {
          acc.configNotAvailable = true;
          acc.isClosed = true;
          return acc;
        }
      }

      acc.rawRate.amount += rateForRoom.amount;
      acc.rawRate.currency = rateForRoom.currency;
      acc.detail.push(rateForRoom.detail);
      acc.roomName.push(rateForRoom.name);
      acc.roomTypeId.push(roomTypeIdForRoom);
      acc.optionKey.push(optionKeyForRoom);
      acc.systemType.push(systemTypeForRoom);

      return acc;
    },
    {
      configNotAvailable: false,
      roomTypeNotPresent: false,
      invalidPax: false,
      isClosed: false,
      rawRate: { amount: 0, currency: 'USD' },
      ruleFound: true,
      roomTypeId: [],
      optionKey: [],
      systemType: [],
      roomName: [],
      detail: [],
    },
  );

  if (rateForAllRooms.ruleFound === false) {
    rateForAllRooms.rawRate = undefined;
  }
  return rateForAllRooms;
};

const ratesForDateForOptionKeys = (ratesMap, date, optionKeys) => {
  let rate = undefined;
  let rates = map(optionKeys, optionKey => {
    forEach(get(ratesMap, optionKey), (value, key) => {
      const fromDate = moment(key.split('_')[0], 'YYYY-MM-DD').utc();
      const toDate = moment(key.split('_')[1], 'YYYY-MM-DD').utc();
      if (
        moment(date, 'YYYY-MM-DD')
          .utc()
          .isBetween(fromDate, toDate, 'day', '[]')
      ) {
        rate = value;
        return false;
      }
    });

    return rate;
  });
  return rates;
};

const minimumAdultsForRoomTypeId = roomTypeId => {
  switch (roomTypeId) {
    // Special case for Wilderness room types 3 & 4 -- family rooms.  They don't allow one adult in a family.
    case '3':
    case '4':
      return 2;
    default:
      return 1;
  }
};

const normalizeRoomType = roomType => {
  switch (roomType) {
    case 'Honeymoon':
    case 'Hmoon':
    case 'Double':
      return '_Double';
    case 'Fam6':
    case 'Fam4':
    case 'Villa':
    case 'Suite':
      return 'Family';
    default:
      return roomType;
  }
};

const WILDERNESS_OVERRIDE_ROOM_ROOMTYPEID = '16842';

const rateKey = ({ optionKey, roomTypeId }) => `${optionKey}-${roomTypeId}`;

export {
  rateForRoomWithRule,
  calculateRateForRooms,
  ratesForDateForOptionKeys,
  optionKeysForSupplierCode,
  formatApproximateValue,
  getCurrencySymbol,
  normalizeRoomType,
  minimumAdultsForRoomTypeId,
  ALL_SYSTEM_TYPES,
  rateKey,
  isWildernessTruthy,
  WILDERNESS_OVERRIDE_ROOM_ROOMTYPEID,
};
