import { useEffect, useState } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { useParams } from 'react-router-dom';

import { Dinero } from 'itrvl-pricing';
import { useCampsInfoMapQuery } from 'queries/camps';
import { useTransitSegments, useTransitAccommodations, useTransitPointsQuery, useTransitPointsSuggestedQuery } from 'queries/transit';
import { useItineraryQuery, useActivitiesBySupplierQuery, useActivitiesByLocationQuery, useConfirmNegativeMargin } from 'api';
import ConfirmDenyModal from 'components/v2/Modals/ConfirmDenyModal';

import logger from 'itrvl-logger';
const log = logger(__filename);

export const useItinerary = () => {
  const { id } = useParams();
  const query = useItineraryQuery(id);
  return {
    id,
    instance: query?.data,
    ...query,
  };
};

export const useSegmentHasDiscount = segment => {
  const discountAmount = Number.parseFloat(segment?.discountAmount || 0);
  const hasDiscount = discountAmount !== 0;
  return { discountAmount, hasDiscount };
};

export const useAmountInTripCurrency = () => {
  const { instance } = useItinerary();
  const currency = instance?.currency ?? 'USD';
  function getAmount(amount = 0, segmentCurrency = 'USD') {
    return Dinero.exchangeSync(Dinero({ amount, currency: segmentCurrency }), currency, {
      [currency]: instance.spotRates,
    });
  }
  return {
    tripCurrency: currency,
    getAmount,
  };
};

// @todo: this seems like it's doing a lot of extra work, can probably be cleaned up
export const useSegmentCost = (segment, instance) => {
  if (!segment) {
    return log.warn('useSegmentCost: segment is not defined');
  }
  if (segment.arrangedBy === 'DMC') {
    return 'Included';
  }
  const currency = instance.currency;
  const segmentCostInTripCurrency = Dinero.exchangeSync(
    Dinero({ unit: segment?.cost || 0, currency: segment?.currency || 'USD' }),
    currency,
    {
      [currency]: instance.spotRates,
    },
  );
  const amount = segmentCostInTripCurrency.getAmount() || 0;
  const segmentCost = Dinero({ amount, currency: instance.currency });
  return {
    segmentCostFormatted: segmentCost.toFormat(),
    segmentCost,
    amount,
  };
};

export const useSegmentSell = (segment, currency) => {
  if (!segment) {
    log.warn('useSegmentSell: segment is not defined');
  }
  if (segment.arrangedBy === 'DMC') {
    return 'Included';
  }
  return Dinero({ unit: segment?.sell || 0, currency }).toFormat();
};

export const useCampInfo = (supplierCode, instance) => {
  const query = useCampsInfoMapQuery({ enabled: Boolean(instance?.id), instance });
  let campInfo = {};
  if (query.isFetched) {
    campInfo = query?.data?.[supplierCode];
  }
  return {
    ...query,
    campInfo,
  };
};

export const useTransitOrig = ({ segment, segments, index, fromOverride, toOverride }) => {
  const query = useTransitSegments({ segment, segments, index, fromOverride, toOverride });
  return {
    ...query,
    results: query.isFetched ? query.data : [],
  };
};

export const useTransit = ({ segment, segments, index }) => {
  const query = useTransitAccommodations({ segment, segments, index });
  return {
    ...query,
    results: query.isFetched ? query.data : {},
  };
};

export const useTransitPoints = () => {
  const query = useTransitPointsQuery();
  return {
    ...query,
    results: query.isFetched ? query.data : {},
  };
};

export const useTransitPointsSuggested = supplierCodes => {
  const query = useTransitPointsSuggestedQuery({ supplierCodes });
  return {
    ...query,
    results: query.isFetched ? query.data : {},
  };
};

export const useActivitiesBySupplier = ({ segment, segments, index }) => {
  const query = useActivitiesBySupplierQuery({ segment, segments, index });
  return {
    ...query,
    results: query.isFetched ? query.data : {},
  };
};

export const useActivitiesByLocation = ({ segment, segments, index }) => {
  const query = useActivitiesByLocationQuery({ segment, segments, index });
  return {
    ...query,
    results: query.isFetched ? query.data : {},
  };
};

export const useNegativeMarginCheck = itinerary => {
  const { mutate: confirmNegativeMarginMutate } = useConfirmNegativeMargin();
  const [ignore, setIgnore] = useState(false);
  useEffect(() => {
    if (itinerary.hasMarginProblem && !itinerary.hasSuppressMarginFlag && !ignore && itinerary.hasPayments) {
      NiceModal.show(ConfirmDenyModal, {
        fullWidth: true,
        maxWidth: 'sm',
        title: 'Negative Margin',
        subtext: 'This itinerary has a negative margin. Payments cannot be made for this itinerary until this is resolved.',
        deniedProps: {
          text: 'Ignore For Now',
        },
        confirmedProps: {
          text: 'Suppress Error',
        },
        onCancel: modal => {
          modal.hide();
          setIgnore(true);
        },
        onConfirm: modal => {
          confirmNegativeMarginMutate({
            itinerary,
            onSuccess: modal.hide,
            onError: error => modal.show({ ...modal.args, loading: false, error }),
          });
          modal.show({ ...modal.args, loading: true });
        },
      });
    }
  }, [itinerary, confirmNegativeMarginMutate, ignore]);
};

export const useSetDocumentTitle = itinerary => {
  useEffect(() => {
    if (itinerary?.name) {
      document.title = `itrvl - ${itinerary.name}`;
    }
    return () => {
      document.title = 'itrvl';
    };
  }, [itinerary]);
};
