import { Typography } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import ArrowLeft from 'components/v2/Icons/ArrowLeft';
import Button from 'components/v2/Button';
import MoreOptionsMenu from './MoreOptionsMenu';
import sharedStyles from 'styles/shared';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4, 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    width: '100%',
    borderBottom: '1px solid #E9E1D7',
  },
  arrow: {
    minWidth: '0',
    padding: theme.spacing(2, 0),
    '& svg': {
      width: 14,
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  actionButton: {
    marginLeft: 'auto',
    textTransform: 'uppercase',
    minWidth: 160,
  },
  inputs: {
    display: 'flex',
    gap: '20px',
  },
}));

export default function Header(props) {
  const { title, agentId, canEdit = false, clients, isArchived, isEmailVerified } = props;
  const history = useHistory();
  const classes = useStyles();
  const s = sharedStyles();

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <Button className={clsx(classes.arrow, 'hoverable')} onClick={() => history.goBack()}>
          <ArrowLeft />
        </Button>
        <Typography variant="h3" className={s.uppercase}>
          {title}
        </Typography>
      </div>

      <div className={classes.inputs}>
        {canEdit && <MoreOptionsMenu agentId={agentId} clients={clients} isArchived={isArchived} isEmailVerified={isEmailVerified} />}
        <Button
          component={Link}
          to={`/agents/${agentId}/clients`}
          color="primary"
          variant="contained"
          disableElevation
          className={classes.actionButton}
        >
          View All Clients
        </Button>
      </div>
    </div>
  );
}
