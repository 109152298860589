import React from 'react';
import { MoreVert } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Divider from '@mui/material/Divider';

import { useArchiveAgentMutation, useSendInviteMutation, useDeleteAgentMutation } from 'api';
import Menu from 'components/Menu';

const useStyles = makeStyles({
  '@global': {
    '.vert-menu': {
      border: '1px solid rgba(0, 0, 0, 0.23);', // Do we have a shared color for this?
      backgroundColor: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 36,
      cursor: 'pointer',
    },
    '.vert-menu-menu-overrides': {
      border: 'none',
      color: '#000',
    },
  },
});

const MoreOptionsMenu = props => {
  const { agentId, clients = [], isArchived = false, isEmailVerified = false } = props;
  const history = useHistory();

  useStyles();

  const archiveAgentMutation = useArchiveAgentMutation();
  const sendInviteMutation = useSendInviteMutation();
  const deleteAgentMutation = useDeleteAgentMutation();

  const handleArchiveAgent = async () => {
    await archiveAgentMutation.mutateAsync({ agentId, isArchive: true });
    // TODO: Should we set form to read-only upon success?  ex: setReadOnly(true) or perhaps some indicator?
  };

  const handleSendInvite = async () => {
    await sendInviteMutation.mutateAsync(agentId);
  };

  const handleRestoreAgent = async () => {
    await archiveAgentMutation.mutateAsync({ agentId, isArchive: false });
  };

  const handleDeleteAgent = async () => {
    await deleteAgentMutation.mutateAsync(agentId);
    history.push('/agents');
  };

  const menuItems = isArchived
    ? [
        {
          key: 'delete',
          label: 'Delete',
          onClick: handleDeleteAgent,
        },
        {
          key: 'restore',
          label: 'Restore',
          onClick: handleRestoreAgent,
        },
      ]
    : [
        {
          key: 'send-invite',
          tooltip: isEmailVerified ? 'Email already verified' : '',
          label: 'Send Email Invite',
          disabled: isEmailVerified,
          onClick: handleSendInvite,
        },
        {
          key: 'divider',
          label: <Divider style={{ width: '100%', color: 'rgba(0, 0, 0, 0.23)' }} />,
          disabled: true,
        },
        {
          key: 'archive',
          tooltip: Array.isArray(clients) && clients.length > 0 ? 'All clients must be re-assigned to archive' : '',
          label: 'Archive',
          disabled: Array.isArray(clients) && clients.length > 0,
          onClick: handleArchiveAgent,
        },
      ];

  return (
    <div className="vert-menu">
      <Menu
        name="more options"
        icon={<MoreVert />}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        items={menuItems}
        classes={{ menu: 'vert-menu-menu-overrides' }}
      />
    </div>
  );
};

export default MoreOptionsMenu;
