import React, { useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { useInView } from 'react-intersection-observer';

function Loader({ fetchNextPage }) {
  const { ref, inView } = useInView({
    rootMargin: '0px 0px 300px 0px',
    // could probably use a ref
    root: document.querySelector('#region-list'),
  });
  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [fetchNextPage, inView]);
  return (
    <div ref={ref} style={{ display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center', justifyContent: 'center' }}>
      <CircularProgress size={18} /> Loading
    </div>
  );
}
export default Loader;
