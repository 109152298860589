import React, { useRef } from 'react';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import { MoreVert } from '@mui/icons-material';
import { Typography, Menu as MuiMenu, MenuItem } from '@mui/material';
import { uniqueId } from 'lodash';

import Tooltip from 'components/v2/Tooltip';

const styles = theme => ({
  root: {},
  menu: {
    padding: '8px 5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    transition: theme.transitions.create(['color']),
    color: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: 4,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.secondary.main,
    },
  },
  disabled: {
    color: 'grey',
  },
  icon: {
    marginRight: 10,
    lineHeight: 0,
  },
});

const DEFAULT_ANCHOR_ORIGIN = {
  vertical: 'bottom',
  horizontal: 'center',
};

const Menu = props => {
  const {
    id: menuId,
    items = [],
    anchorOrigin = DEFAULT_ANCHOR_ORIGIN,
    transformOrigin,
    classes,
    'data-test-id': dataTestId,
    icon = <MoreVert />,
    disabled,
    name,
    ...restProps
  } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    if (!disabled) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = event => {
    setAnchorEl(null);
    event?.stopPropagation();
  };
  const instanceId = useRef(menuId || uniqueId('menu-')).current; // useRef should get a new instanceId that persists across renders
  return (
    <Typography component="div">
      <div
        className={clsx(classes.menu, disabled ? classes.disabled : '')}
        onClick={handleClick}
        data-test-id={dataTestId}
        role="button"
        aria-label={name}
        {...restProps}
      >
        {icon}
      </div>
      <MuiMenu
        id={instanceId}
        disabled={disabled}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        data-test-id={dataTestId ? `${dataTestId}-menu` : undefined}
        className="menu"
        classes={{
          list: 'menu',
        }}
      >
        {items.map((item, index) => {
          const {
            icon,
            label,
            onClick,
            'data-test-id': itemTestId,
            component: Component,
            props,
            tooltip,
            disabled,
            key,
            ...itemProps
          } = item;

          const uniqueKey = `${instanceId}-${key || index}`;

          if (Component) {
            return <Component key={uniqueKey} handleClose={handleClose} {...props} />;
          }

          return (
            <Tooltip title={tooltip} disabled={!tooltip} placement="left" key={uniqueKey}>
              <div>
                <MenuItem
                  className={clsx('menu_menu-item', disabled && 'Mui-disabled linkDisabled')}
                  data-test-id={itemTestId || dataTestId ? `${dataTestId}-item` : undefined}
                  disableRipple={disabled}
                  aria-disabled={disabled}
                  onClick={event => {
                    if (disabled) {
                      event.stopPropagation();
                      return;
                    }
                    onClick?.();
                    handleClose(event);
                  }}
                  {...itemProps}
                >
                  {icon && <span className={classes.icon}>{icon}</span>}
                  {label}
                </MenuItem>
              </div>
            </Tooltip>
          );
        })}
      </MuiMenu>
    </Typography>
  );
};

export default withStyles(styles)(Menu);
