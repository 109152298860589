import React from 'react';
import Loader from './AutocompleteLoader';

const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
  const { hasNextPage, fetchNextPage, isLoading, ...rest } = props;
  return (
    <ul {...rest} ref={ref}>
      {props.children}
      {/* bolt on an end of list listener */}
      {!isLoading && hasNextPage && <Loader fetchNextPage={fetchNextPage} />}
    </ul>
  );
});

export default ListboxComponent;
