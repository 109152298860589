export const formatFullName = (firstName, lastName) => {
  return [firstName, lastName].filter(Boolean).join(' ');
};

export const formatCommunicationPreference = preferences => {
  if (!preferences || typeof preferences !== 'object') {
    return 'N/A';
  }
  const enabledPreferences = Object.keys(preferences)
    .filter(key => preferences[key])
    .map(key => {
      let formattedKey = key.replace(/Call$/, ''); // Remove "Call"
      return formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1); // Capitalize first character
    });
  return enabledPreferences.length > 0 ? enabledPreferences.join(', ') : 'None';
};
