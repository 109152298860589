import { useMemo, useRef } from 'react';
/**
 * This hook provides all logic, variables, and handlers
 * needed to support both flexing the column sizes on
 * first load, AND resizing columns using the header handles
 *
 * It works by leveraging a grid layout and minmax widths
 * By default, everything will be layed out using minmax(Npx, 1fr)
 * Where Npx is what is calculated by react-table or supplied by the user
 * We do not do this with items with a max-width, as we want to make sure
 * they do not extend beyond the max-width (1fr will allow it to expand)
 *
 * 150 is the default max-width supplied by react-table, which is ignored
 * by using minmax(px, 1fr). We also require the actual maxWidth to be set
 * within the meta for a column in order to know columns with a "true" maxWidth.
 * The end result is that the table is layed out in an optimal way respecting
 * the maxWidth specified by the developer, the optimal values calculated by
 * the table, and the flexed widths calculated by the browser
 *
 * For resizing, we need to make react-table aware of the rendered widths
 * as we rely on the browser to do the layout, and react-table becomes
 * out of sync. To account for this, we expose a custom getResizeHandler
 * which updates the table with the DOM rendered widths before invoking resize
 */
export function useGridFlexLayout({ table, headerRef }) {
  /**
   * Store the resized columns in a ref so we can use them later
   * It would be nice if I could get this value from react-table,
   * but it doesn't seem to be exposed (?)
   */
  const resizedColumnsStatic = useRef({});
  /**
   * Instead of calling `column.getSize()` on every render for every header
   * and especially every data cell (very expensive),
   * we will calculate all column sizes at once at the root table level in a useMemo
   * and use grid template styles and CSS vars to lay out the table
   *
   * We also handle resizing and flexing the columns here
   */
  const gridTemplateString = useMemo(() => {
    const headers = table.getFlatHeaders();

    // The last column without a maxWidth will always have a minmax with 1fr
    // This ensures that the column fills remaining width in the table container
    // if other columns are resized to be smaller
    const lastColumnWithoutMaxWidth = headers.findLast(h => !h.column.columnDef.meta?.maxSize);

    const gridTemplate = headers.map(header => {
      const isLastResizableColumn = lastColumnWithoutMaxWidth === header;
      const currentSize = header.getSize();
      const sizeInPx = `${currentSize}px`;

      // Update our ref of what is being resized - we need to explicitly
      // set the resized widths and prefer them over the flex widths
      if (header.column.getIsResizing()) {
        resizedColumnsStatic.current[header.id] = currentSize;
      }

      const hasMaxSize = header.column.columnDef.meta?.maxSize;
      const isResizing = header.column.getIsResizing();
      const hasBeenResized = resizedColumnsStatic.current[header.id];

      // Prefer the size calculated by react-table if the col has a maxSize
      if (hasMaxSize) {
        return sizeInPx;
      }
      // If we are resizing, or already resized, this column, use the PX value
      // unless it's the last resizable column, which always needs 1fr
      if ((isResizing || hasBeenResized) && !isLastResizableColumn) {
        return sizeInPx;
      }

      // The default for all columns is to fill the remaining space
      // also, the last resizeable column will always flex to fill any space
      // caused while resizing columns to the left of it
      return `minmax(${sizeInPx}, 1fr)`;
    });

    const gridTemplateJoined = gridTemplate.join(' ');

    return gridTemplateJoined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [table.getState().columnSizingInfo]);

  return {
    gridTemplateVarsStyle: {
      '--grid-template-columns': gridTemplateString,
    },
    gridTemplateString, // useful to render for debugging :)
    getResizeHandler: header => event => {
      // Note: This relies on data-id being present within the DOM
      const headerElement = Array.from(headerRef.current?.childNodes ?? []).find(
        node => node instanceof HTMLElement && node.getAttribute('data-id') === header.id,
      );

      // Very quickly set the column width to the one which was calculated by DOM rendering
      // This is so that we can rely on DOM rendering to layout the table, but when we
      // go to resize columns, react-table is aware of the current column width
      if (headerElement) {
        table.setColumnSizing({
          ...resizedColumnsStatic.current,
          [header.column.id]: headerElement.getBoundingClientRect().width,
        });
      }

      // before invoking resize handler, allow the underling table to be updated
      setTimeout(() => header.getResizeHandler()(event));
    },
  };
}
