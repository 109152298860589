import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApi } from 'common/hooks/api';
import { useSnackbar } from 'notistack';
import { handleError } from 'components/handleSnackBarMessages';
import Agency from 'models/Agency';

import logger from 'itrvl-logger';
const log = logger(__filename);

export const patchAgencyKey = agencyId => ['agency', agencyId, 'my-agency-settings'];

export const usePatchAgencyMutation = () => {
  const Api = useApi();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: async ({ agencyId, data }) => {
      return Api.patchAgency(agencyId, data);
    },
    onSuccess: (_, { agencyId }) => {
      queryClient.invalidateQueries(['agency', agencyId]);

      // For removing id from admin array (lead change)
      queryClient.refetchQueries(['agency', agencyId]);
      queryClient.invalidateQueries(['agent']);
    },
    onError: err => {
      handleError(err, 'An error occurred while updating the agency.', enqueueSnackbar, log);
    },
  });
};

// * Used in My Agency
export const useUpdateAgency = () => {
  const Api = useApi();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ agencyId, agency }) => {
      await Api.patchAgency(agencyId, agency);
      const response = await Api.getAgency(agencyId);
      return new Agency(response?.data);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['myAgencySettings']);
    },
    onError: err => {
      log.error('Agency update failed', err);
    },
  });
};
