import React from 'react';
import { CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

import { formatCommunicationPreference, formatFullName } from './utils/clientUtils';
import AgentClientTable from './AgentClientTable';

const useStyles = makeStyles(
  _theme => ({
    root: {
      display: 'flex',
      gap: '10px',
      flexDirection: 'column',
    },
    listHeader: {
      display: 'grid',
      gridTemplateColumns: 'auto 200px',
      alignItems: 'center',
      padding: '0 0 10px 16px',
      '& .title': {
        fontSize: 16,
        fontWeight: '400',
        lineHeight: '30px',
        letterSpacing: '0em',
        paddingBottom: '10px',
        color: '#221F20',
      },
      '& .subtitle': {
        fontSize: '12px',
        color: 'rgba(34, 31, 32, 0.50)',
      },
    },
    loading: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: '100px',
      '& .text': {
        fontSize: '1rem',
        padding: '20px',
      },
    },
    profileImage: {
      display: 'flex',
      width: '30px',
      height: '30px',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      flexShrink: 0,
    },
    image: {
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      objectFit: 'cover',
    },
    icon: {
      width: '16px',
      height: '16px',
      marginBottom: '4px',
    },
  }),
  {
    name: 'AgentClientList',
  },
);

const AgentClientList = props => {
  const { clientList, isLoading } = props;
  const classes = useStyles();

  const columns = React.useMemo(
    () => [
      {
        header: 'Name',
        accessorKey: 'lastName',
        cell: ({ row }) => formatFullName(row.original.firstName, row.original.lastName),
      },
      {
        header: 'Phone',
        accessorKey: 'contact.phone',
      },
      {
        header: 'Email',
        accessorKey: 'email',
      },
      {
        header: 'Communication Preference',
        accessorKey: 'contact.communicationPreferences',
        cell: ({ row }) => formatCommunicationPreference(row.original?.contact?.communicationPreferences),
      },
      {
        header: 'Country',
        accessorKey: 'passport.country',
      },
      {
        header: () => <></>,
        id: 'details',
        cell: ({ row }) => (
          <Link className={clsx('link hoverable')} to={`/clients/${row.original.id}`}>
            View Details
          </Link>
        ),
      },
    ],
    [],
  );

  return (
    <div className={classes.root}>
      <div className={classes.listHeader}>
        <div className="title">
          {isLoading ? 'Loading clients...' : `${clientList?.length || 0} Total Client${clientList?.length === 1 ? '' : 's'}`}
        </div>
      </div>
      {isLoading ? (
        <div className={classes.loading}>
          <div className="text">Getting client list</div>
          <div>
            <CircularProgress size={60} data-test-id="loading-svg" />
          </div>
        </div>
      ) : (
        <AgentClientTable data={clientList} columns={columns} noResultsText="No clients found" />
      )}
    </div>
  );
};

export default AgentClientList;
