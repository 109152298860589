export default function ArrowLeft() {
  return (
    <svg viewBox="0 0 15 10" fill="none" id="test" aria-label="go back" role="img">
      <title>go back</title>
      <path
        d="M1.24609 4.4668L4.74609 0.966797C5.07422 0.611328 5.64844 0.611328 5.97656 0.966797C6.33203 1.29492 6.33203 1.86914 5.97656 2.19727L3.98047 4.19336H14.125C14.5898 4.19336 15 4.60352 15 5.06836C15 5.56055 14.5898 5.94336 14.125 5.94336H3.98047L5.97656 7.9668C6.33203 8.29492 6.33203 8.86914 5.97656 9.19727C5.64844 9.55273 5.07422 9.55273 4.74609 9.19727L1.24609 5.69727C0.890625 5.36914 0.890625 4.79492 1.24609 4.4668Z"
        fill="currentColor"
      />
    </svg>
  );
}
