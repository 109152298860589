export const CONTENT_TEAM = 'CONTENT_TEAM';
/**
 * Takes a user, if from the content team, will use the content team id for a patch
 * @param user - A whoAmI user
 * @returns {string}
 */
export const getAgencyIdForPatch = user => (user.agency?.agencyCode?.toUpperCase() === 'ITR006' ? CONTENT_TEAM : user.agency?.id);
/**
 * Takes a user, and record id and concats them for a patch and gives the id
 * @param user
 * @param id
 * @returns {`${agencyId}-${recordId}`}
 */
export const getRecordIdForPatch = (user, id) => `${getAgencyIdForPatch(user)}-${id}`;
/**
 * Takes a user, and record id and concats them for a patch and gives an object with an _id property
 * @param user
 * @param id
 * @returns {object}
 */
export const getRecordIdObjectForPatch = (user, id) => ({ _id: getRecordIdForPatch(user, id) });
